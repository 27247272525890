export const convertToFile = async (Image, name) => {
    const imgType = Image.split(";base64")[0].split(":")[1];
    const ext = imgType.split("/")[1];
    const pImage = await urltoFile(Image, `${name}.${ext}`, imgType)
    return pImage;
}

const urltoFile = (url, filename, mimeType) => {
    if (url.startsWith('data:')) {
        const arr = url.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[arr.length - 1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        const file = new File([u8arr], filename, { type: mime || mimeType });
        return Promise.resolve(file);
    }
    return fetch(url)
        .then(res => res.arrayBuffer())
        .then(buf => new File([buf], filename, { type: mimeType }));
}

export const convertBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);    
    return new Promise(resolve => {
        reader.onload = function () {
            resolve(reader.result);
        };
    });
    // reader.onerror = function (error) {
    //     console.log('Error: ', error);
    // };
}