import axios from "axios";
import config from "../../config.json";
const token = localStorage.getItem("jwtToken");
//////////// GET SEND STATUS START///////
export const getSendStatus = async (data) => {
	const getBalanceCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/SendCheckStaus",
	};

	try {
		const balance = await axios(getBalanceCofig);
		if (balance.status === 200) {
			return balance;
		}
	} catch (e) {
		return e.response;
	}
};
//////////// GET SEND STATUS END///////

//////////// Get Receive nft service start///////
export const getAcceptNFTStatus = async (data) => {
	const getAcceptStatusCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/CheckAcceptStatus",
	};

	try {
		const data = await axios(getAcceptStatusCofig);
		if (data.status === 200) {
			return data;
		}
	} catch (e) {
		return e.response;
	}
};
//////////// Get Receive nft service end///////

//////////// Get declined nft service start///////
export const getDeclinedNFTStatus = async () => {
	const getDeclinedStatusCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		method: "post",
		url: "xrpl/ReceiverDeclinedDetails",
	};

	try {
		const data = await axios(getDeclinedStatusCofig);
		if (data.status === 200) {
			return data;
		}
	} catch (e) {
		return e.response;
	}
};
//////////// Get declined nft service end///////


//////////// Declined nft service start///////
export const declinedNFTRequest = async (data) => {
	const declinedNFTRequestCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/DeclineNft",
	};

	try {
		const data = await axios(declinedNFTRequestCofig);
		if (data.status === 200) {
			return data;
		}
	} catch (e) {
		return e.response;
	}
};
////////////  Declined nft service end///////