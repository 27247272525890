import { actionTypes } from "../actionTypes/mintNft";
import {
  mintNft,
  burnNft,
  saleNft,
  cancelSaleNft,
  buyNft,
  bidToken,
  bidCancelToken,
  bidAndBurnToken,
  allMintedNft,
  deleteNft,
  totalTrade,
  sendNft,
  receiveNft,
  cancelSendNft,
  placeMoreOffer,
  getAllPlacedOffers,
  acceptPlacedOffer,
  allCancelPlacedOffer,
  cancelPlacedOffer,
  allOfferByNftOwner
} from "../services/mintNft";

///////////////////////// MINT NFT ACTION ////////////////

export let mintNftAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MINT_REQUEST, loader: data.loader });
  const payload = await mintNft(data.data);
  const { status } = payload;
  if (status === 200) {
    dispatch({ type: actionTypes.MINT_SUCCESS, payload: payload.data });
  } else {
    dispatch({ type: actionTypes.MINT_FAILURE, payload });
  }
};

/////////////////////////////////////// BURN NFT ACTION ///////////////////////////////

export let burnNftAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.BURN_REQUEST, loader: data.loader });
  const payload = await burnNft(data.data);
  const { status } = payload;
  if (status === 200) {
    dispatch({ type: actionTypes.BURN_SUCCESS, payload: payload.data });
  } else {
    dispatch({ type: actionTypes.BURN_FAILURE, payload });
  }
};

///////////////////////////////////////  SALE ACTION ///////////////////////////////

export let saleNftAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.SALE_REQUEST, loader: data.loader });
  const payload = await saleNft(data.data);
  const { status } = payload;
  if (status === 200) {
    dispatch({ type: actionTypes.SALE_SUCCESS, payload: payload.data });
  } else {
    dispatch({ type: actionTypes.SALE_FAILURE, payload });
  }
};

//////////////////////////////////////  CANCEL SALE ACTION ///////////////////////////////

export let cancelSaleNftAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CANCEL_SALE_REQUEST, loader: data.loader });
  const payload = await cancelSaleNft(data.data);
  const { status } = payload;
  if (status === 200) {
    dispatch({ type: actionTypes.CANCEL_SALE_SUCCESS, payload: payload.data });
  } else {
    dispatch({ type: actionTypes.CANCEL_SALE_FAILURE, payload });
  }
};

//////////////////////////////////////  BUY ACTION ///////////////////////////////

export let buyNftAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.BUY_REQUEST, loader: data.loader });
  const payload = await buyNft(data.data);
  const { status } = payload;
  if (status === 200) {
    dispatch({ type: actionTypes.BUY_SUCCESS, payload: payload.data });
  } else {
    dispatch({ type: actionTypes.BUY_FAILURE, payload });
  }
};

//////////////////////////////////////  BID TOKEN ACTION ///////////////////////////////

export let bidTokenAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.BID_REQUEST, loader: data.loader });
  const payload = await bidToken(data.data);
  const { status } = payload;
  if (status === 200) {
    dispatch({ type: actionTypes.BID_SUCCESS, payload: payload.data });
  } else {
    dispatch({ type: actionTypes.BID_FAILURE, payload });
  }
};

////////////////////////////////////// CANCEL BID TOKEN ACTION ///////////////////////////////

export let bidCancelTokenAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CANCEL_BID_REQUEST, loader: data.loader });
  const payload = await bidCancelToken(data.data);
  const { status } = payload;
  if (status === 200) {
    dispatch({ type: actionTypes.CANCEL_BID_SUCCESS, payload: payload.data });
  } else {
    dispatch({ type: actionTypes.CANCEL_BID_FAILURE, payload });
  }
};

//////////////////////////////////////  BID TOKEN ACTION ///////////////////////////////

export let bidAndBurnTokenAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.BID_AND_BURN_REQUEST, loader: data.loader });
  const payload = await bidAndBurnToken(data.data);
  const { status } = payload;
  if (status === 200) {
    dispatch({ type: actionTypes.BID_AND_BURN_SUCCESS, payload: payload.data });
  } else {
    dispatch({ type: actionTypes.BID_AND_BURN_FAILURE, payload });
  }
};

////////////////////////////////////// NUMBER OF MINTED ACTION///////////////////////////////

export let numberOfMintedAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.NUMBER_OF_MINTED_REQUEST, loader: data.loader });
  const payload = await allMintedNft();
  const { status } = payload;
  if (status === 200) {
    dispatch({
      type: actionTypes.NUMBER_OF_MINTED_SUCCESS,
      payload: payload.data,
    });
  } else {
    dispatch({ type: actionTypes.NUMBER_OF_MINTED_FAILURE, payload });
  }
};

// ===================DELETE NFT ACTION==================

export let deleteNftAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETENFT_REQUEST, loader: data.loader });
  const payload = await deleteNft(data.data);
  const { status } = payload;
  if (status === 200) {
    dispatch({ type: actionTypes.DELETENFT_SUCCESS, payload: payload.data });
  } else {
    dispatch({ type: actionTypes.DELETENFT_FAILURE, payload });
  }
};

/* Total traded NFT action */
export let totalTradeAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.TOTALTRADE_REQUEST, loader: data.loader });
  const payload = await totalTrade();
  const { status } = payload;
  if (status === 200) {
    dispatch({ type: actionTypes.TOTALTRADE_SUCCESS, payload: payload.data });
  } else {
    dispatch({ type: actionTypes.TOTALTRADE_FAILURE, payload });
  }
};

///////////////////////////////////////  SEND NFT ACTION ///////////////////////////////

export let sendNftAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.SEND_REQUEST, loader: data.loader });
  const payload = await sendNft(data.data);
  const { status } = payload;
  if (status === 200) {
    dispatch({ type: actionTypes.SEND_SUCCESS, payload: payload.data });
  } else {
    dispatch({ type: actionTypes.SEND_FAILURE, payload });
  }
};

//////////////////////////////////////  RECEIVE NFT ACTION ///////////////////////////////

export let receiveNftAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.RECEIVE_REQUEST, loader: data.loader });
  const payload = await receiveNft(data.data);
  const { status } = payload;
  if (status === 200) {
    dispatch({ type: actionTypes.RECEIVE_SUCCESS, payload: payload.data });
  } else {
    dispatch({ type: actionTypes.RECEIVE_FAILURE, payload });
  }
};

//////////////////////////////////////  CANCEL SEND ACTION ///////////////////////////////

export let cancelSendNftAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CANCEL_SEND_REQUEST, loader: data.loader });
  const payload = await cancelSendNft(data.data);
  const { status } = payload;
  if (status === 200) {
    dispatch({ type: actionTypes.CANCEL_SEND_SUCCESS, payload: payload.data });
  } else {
    dispatch({ type: actionTypes.CANCEL_SEND_FAILURE, payload });
  }
};

/* PLACE MORE OFFER ACTION */
export let placeMoreOfferAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.PLACE_MOREOFFER_REQUEST, loader: data.loader });
  const payload = await placeMoreOffer(data.data);
  const { status } = payload;
  if (status === 200) {
    dispatch({
      type: actionTypes.PLACE_MOREOFFER_SUCCESS,
      payload: payload.data,
    });
  } else {
    dispatch({ type: actionTypes.PLACE_MOREOFFER_FAILURE, payload });
  }
};

/* Get all placed offers */
export let getAllPlacedOffersAction = (data) => async (dispatch) => {
	dispatch({ type: actionTypes.GET_ALLPLACEDOFFER_REQUEST, loader: data.loader });
	const payload = await getAllPlacedOffers(data.nftId);
	const { status } = payload;
	if (status === 200) {
	  dispatch({
		type: actionTypes.GET_ALLPLACEDOFFER_SUCCESS,
		payload: payload.data,
	  });
	} else {
	  dispatch({ type: actionTypes.GET_ALLPLACEDOFFER_FAILURE, payload });
	 }
  };

  /* Accept placed offers action */
export let acceptPlacedOffersAction = (data) => async (dispatch) => {
	dispatch({ type: actionTypes.ACCEPT_PLACEDOFFER_REQUEST, loader: data.loader });
	const payload = await acceptPlacedOffer(data.data);
	const { status } = payload;
	if (status === 200) {
	  dispatch({
		type: actionTypes.ACCEPT_PLACEDOFFER_SUCCESS,
		payload: payload.data,
	   });
	} else {
	  dispatch({ type: actionTypes.ACCEPT_PLACEDOFFER_FAILURE, payload });
	   }
  };

  /* All placed offer to be cancelled action */
  export let allCancelPlacedOffersAction = (data) => async () => {
    const payload = await allCancelPlacedOffer(data);
    return payload
      };

      /* Cancel placed offer action */
  export let cancelPlacedOffersAction = (data) => async (dispatch) => {
     dispatch({ type: actionTypes.CANCEL_PLACEDOFFERS_REQUEST, loader: data.loader });
    const payload = await cancelPlacedOffer(data.data);
     const { status } = payload;
     if (status === 200) {
         dispatch({ type: actionTypes.CANCEL_PLACEDOFFERS_SUCCESS, payload: payload.data });
       } else {
         dispatch({ type: actionTypes.CANCEL_PLACEDOFFERS_FAILURE, payload });
         }
      };

      /* All buy offers received to owner */
  export let allOfferByNftOwnerAction = () => async () => {
        const payload = await allOfferByNftOwner();
        return payload;
          };