import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Dropdown from 'react-bootstrap/Dropdown';

import "react-toastify/dist/ReactToastify.css";
import {
  Row,
  Col,
  Button,
  OverlayTrigger,
  Popover,
  Navbar,
  Nav,
  Container,
  Modal
} from "react-bootstrap";
// import SmallLogo from "../../assets/newLogo.png";
import { useJwt, decodeToken } from "react-jwt";
import { useDispatch, useSelector } from "react-redux";
import {
  connectWalletAction,
  accountDetailAction,
  getBalanceAction,
  registrationFee,
  disConnectWalletAction,
} from "../../store/actions/wallet";
import {
  numberOfMintedAction,
  totalTradeAction,
  allCancelPlacedOffersAction,
  allOfferByNftOwnerAction
} from "../../store/actions/mintNFT";
import { checkDeclinedNFTAction } from "../../store/actions/send";

import { homeNftDetail } from "../../store/actions/homedetail";
import MessageConst from "../../const/message.json";
import Form from "react-bootstrap/Form";
import SearchNft from "../nft/searchNft";
// import HashLoader from "react-spinners/HashLoader";
import Swal from "sweetalert2";
import SendModel from "./SendModel";
import * as Spinners from "react-loader-spinner";

//logo
import Xamen from "../../assets/xaman.jpg"
import MetaMask from "../../assets/metamask.jpeg"
import WalletConnect from "../../assets/walletconnect.png"
import FUSIONXIO from "../../assets/fusion-x-logo.jpg"
import FUSIONLOGO from "../../assets/fusion-logo.png"
import BACKGROUNDIMG from "../../assets/registration.jpg"


const Header = ({ setSearchKey, setIsActiveWallet }) => {
  const { pathname } = useLocation();

  const token = localStorage.getItem("jwtToken");
  const { isExpired } = useJwt(token);
  const myDecodedToken = decodeToken(token);
  const navigate = useNavigate();
  const [showModal, setShowModel] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [forMobile, setForMobile] = useState("");
  const [walletEnable, setWalletEnable] = useState(false);
  const [currencyBalance, setCurrencyBalance] = useState([]);
  const [totalMintedNft, setTotalMintedNft] = useState(0);
  const [APIData, setAPIData] = useState([]);
  const [allMintedNfts, setAllMintedNfts] = useState(null);
  const [searchInput, setSearchInput] = useState(null);
  const [filteredResults, setFilteredResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchVal] = useState(true);
  const [totalTrade, setToatalTrade] = useState(0);
  const [declinedData, setDeclinedData] = useState(null);
  const [disconnect, setDisconnect] = useState(false);
  const [calcelAllPlacedOffer, setCalcelAllPlacedOffer] = useState(null)
  const [buyOfferByNftOwner, setBuyOfferByNftOwner] = useState(null);
  const [totalCount, setTotalCount] = useState(null)
  const [modalView, setModalView] = useState(false);

  // token
  // const JWTToken = localStorage.getItem("jwtToken")

  const override = {
    position: "absolute",
    left: "50%",
    top: "50%",
  };
  let dispatch = useDispatch();

  const [
    walletConnectStatus,
    accountDetail,
    balance,
    registrationFeeStatus,
    disConnectStatus,
    totalNumberOfMinted,
    homedtl,
    totalTrades,
  ] = useSelector((state) => [
    state.walletActionReducer,
    state.accountDetailReducer,
    state.getBalanceReducer,
    state.registrationFeeReducer,
    state.walletDisConnectReducer,
    state.numberOfMintedReducer,
    state.homeDetailReducer,
    state.totalTradeReducer,
  ]);

  useEffect(() => {
    setTotalCount(declinedData?.length + calcelAllPlacedOffer?.length + buyOfferByNftOwner?.length)
  }, [declinedData, calcelAllPlacedOffer, buyOfferByNftOwner])

  /* All offer by nft owner */
  useEffect(() => {
    // const token = localStorage.getItem("jwtToken");
    if (!!token) {
      dispatch(allOfferByNftOwnerAction())
        .then((val) => {
          setBuyOfferByNftOwner(val.data.totalOffer);
        })
        .catch((e) => {
          console.log("Error all offer by nft owner:", e);
        });
    }

  }, [token]) // eslint-disable-line

  /* All placed offer record to be cancelled */
  useEffect(() => {
    // const token = localStorage.getItem("jwtToken");
    if (!!token) {
      dispatch(allCancelPlacedOffersAction({ token }))
        .then((val) => {
          setCalcelAllPlacedOffer(val.data.offerDataSignedUser);
        })
        .catch((e) => {
          console.log("Error all offer to be cancelled useEffect:", e);
        });
    }

  }, [token])// eslint-disable-line

  // ===========Declined receive nft record======
  useEffect(() => {
    const pathValue = pathname.split("/").pop();
    if (
      !["Today_picks", "Recommended_collections"].includes(pathValue) &&
      pathValue.length !== 24
    ) {
      localStorage.removeItem("page");
    }

    // const token = localStorage.getItem("jwtToken");
    if (!!token) {
      dispatch(checkDeclinedNFTAction({ token }))
        .then((val) => {
          setDeclinedData(val.data.data);
        })
        .catch((e) => {
          console.log("Error check declined NFT:", e);
        });
    }
  }, [token]);// eslint-disable-line

  // =============search Nft ===============
  useEffect(() => {
    try {
      dispatch(homeNftDetail({ loader: true }));
    } catch (error) {
      toast.error(error.response.data.message, {
        toastId: "searchNft",
      });
    }
  }, []);// eslint-disable-line

  useEffect(() => {
    if (homedtl.nftDetail !== "") {
      const { allNft, allMintedNft } = homedtl.nftDetail;
      setAPIData(allNft);
      setAllMintedNfts(allMintedNft);
    }
  }, [homedtl]);// eslint-disable-line

  ////////////////////////  MODEL START ///////
  const handleClose = () => {
    setShowModel(false);
    setModalView(true)
  }
  ////////////////////////  MODEL END ///////

  //// REGISTRATION FEE STATUS START//////////////
  useEffect(() => {
    if (registrationFeeStatus.data != null) {
      if (registrationFeeStatus.error === null) {
        localStorage.setItem("jwtToken", registrationFeeStatus.data.token);
        window.location.reload();
      } else {
        toast.error(registrationFeeStatus?.data?.data?.message, {
          toastId: "registrationFeeStatuserror",
          onClose: () => {
            window.location.reload();
          },
        });
      }
    }
  }, [registrationFeeStatus]);// eslint-disable-line



  //// REGISTRATION FEE STATUS END//////////////
  ////////////////////// ACCOUNT DEATIL START//////////
  useEffect(() => {
    if (accountDetail.error) {
      toast.error(accountDetail.error.message, {
        toastId: "accountDetailerror",
        onClose: () => {
          window.location.reload();
        },
      });
    } else if (accountDetail?.account !== null) {
      if (accountDetail?.account?.user_type === "secondTime") {
        localStorage.setItem("jwtToken", accountDetail?.account.token);
        window.location.reload();
      } else {
        Swal.fire({
          title: "Registration",
          html: `<p class="swalPara">You are required to pay a 1 time registration fee of 5 XRP to use FUZION-XIO.`,
          // html: `<p class="swalPara">You are required to pay a 1 time registration fee of 5 XRP to use FUZION-XIO.</P>
          // <ul class="swallList">
          // <li>Unlimited Minting</li>&nbsp;
          // <li>0% Trading fee</li>&nbsp;&nbsp;&nbsp;
          // <li>All XRPL tokens tradable</li>
          // </ul>`,
          imageUrl: FUSIONLOGO,
          imageWidth: 226,
          imageHeight: 60,
          showCancelButton: true,
          confirmButtonColor: "#b00fb5",
          cancelButtonColor: "#b00fb5",
          confirmButtonText: "Connect",
          customClass: {
            container: "swal-container",
            actions: 'vertical-buttons',
            cancelButton: 'cancel-btn',
            confirmButton: 'confirm-btn'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            let data = {
              account: accountDetail.account.ac,
              issuedToken: accountDetail.account.issued_token,
            };
            dispatch(registrationFee({ data, loader: true }));
          } else {
            toast.error(MessageConst.accountActivateError, {
              toastId: "accountDetailNotConfurm",
              onClose: () => {
                window.location.reload();
              },
            });
          }
        });
      }
    }
  }, [accountDetail, walletEnable]); // eslint-disable-line

  ////////////////////// ACCOUNT DEATIL END//////////
  ////////////////////// Balance Start//////////
  useEffect(() => {
    if (balance?.currency !== null) {
      if (balance?.currency?.currency) {
        setCurrencyBalance(balance?.currency?.currency);
      } else {
        toast.error(MessageConst.alertDevnetAccountLogin, {
          toastId: "accountBalance",
          onClose: () => {
            localStorage.clear();
            // window.location.reload();
            navigate("/")
          },
        });
      }
    }
  }, [balance]); // eslint-disable-line

  useEffect(() => {
    // typeof setIsActiveWallet()
    setIsActiveWallet !== undefined && setIsActiveWallet(token);
    try {
      if (token !== null) {
        let data = {
          token: token,
        };
        dispatch(getBalanceAction({ data, loader: true }));
      }
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "connectWallet1",
      });
    }
  }, [token]); // eslint-disable-line

  ////////////////////// Balance END//////////

  ////////////// XUMM CONNECT START///////////////////////
  useEffect(() => {
    if (walletConnectStatus?.wallet && walletEnable) {
      if (walletConnectStatus?.wallet?.message) {
        setQrCode(walletConnectStatus?.wallet?.message);
        setForMobile(walletConnectStatus?.wallet?.forMobile);
        setShowModel(true);
        let data = {
          uuid: walletConnectStatus?.wallet?.uuid,
        };
        dispatch(accountDetailAction({ data, loader: true }));
        setTimeout(() => {
          window.location.reload();
        }, 60000);
      } else {
        alert(MessageConst.alertTryAfterSometime);
      }
    } else if (walletConnectStatus.error !== null) {
      toast.warn(walletConnectStatus.error.data.message, {
        toastId: "walleterror",
      });
    }
  }, [walletConnectStatus, walletEnable]); // eslint-disable-line

  const connectWallet = async () => {
    setModalView(false)
    setWalletEnable(true);
    try {
      dispatch(connectWalletAction({ loader: true }));
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "connectWallet1",
      });
    }
  };

  // disconnect wallet
  useEffect(() => {
    if (disConnectStatus?.data && disconnect) {
      if (disConnectStatus?.data?.message) {
        localStorage.clear();
        toast.success(disConnectStatus?.data?.message, {
          toastId: "connectsisconnSS",
          onClose: () => {
            setDisconnect(false);
            navigate("/");
          },
        });
      } else if (disConnectStatus?.data?.data) {
        localStorage.clear();
        toast.error(disConnectStatus?.data?.data?.message, {
          toastId: "connectsisconnSStr",
          onClose: () => {
            setDisconnect(false);
            navigate("/");
          },
        });
      }
    }
  }, [disConnectStatus, disconnect]); // eslint-disable-line

  // handle vscoredashboard
  const vScoreDashboard = () => {
    navigate("/Vscoredashboard")
  }

  const disconnectWallet = async () => {
    setDisconnect(true);
    if (isExpired === true || token === null) {
      localStorage.clear();
      toast.error(MessageConst.errorConnectXummWallet, {
        toastId: "connectWallet121",
      });
      window.location.reload();
    }
    try {
      dispatch(disConnectWalletAction({ loader: true }));
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "connectWallet11111",
      });
    }
  };

  ////////////// XUMM CONNECT END///////////////////////

  ///////////// NUMBER OF MINTED START/////////////
  useEffect(() => {
    if (totalNumberOfMinted?.error !== null) {
      setTotalMintedNft(totalNumberOfMinted?.numberOfMinted?.totalNftMinted);
    }
  }, [totalNumberOfMinted]); // eslint-disable-line

  useEffect(() => {
    try {
      dispatch(numberOfMintedAction({ loader: true }));
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "numberOfMintedDispanch",
      });
    }
  }, []); // eslint-disable-line

  // Total trade history
  useEffect(() => {
    try {
      dispatch(totalTradeAction({ loader: true }));
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "numberOfMintedDispanch",
      });
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    try {
      if (totalTrades?.error === false) {
        setToatalTrade(totalTrades?.totalTrade?.totalTradeHistory);
      }
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "totalTrade",
      });
    }
  }, [totalTrades]); // eslint-disable-line

  let filterTimeout;
  ///////////// NUMBER OF MINTED END/////////////
  const searchItems = (searchValue) => {
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      setLoading(true);
      if (searchValue !== "") {
        const filteredData = APIData?.filter((item) => {
          return Object.values(item)
            .join("")
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        });
        setSearchKey(false);
        setSearchVal(false);

        filterTimeout = setTimeout(() => {
          setFilteredResults(filteredData);
          setLoading(false);
          setSearchInput(searchValue);
        }, 500);
      } else {
        setLoading(false);
        setSearchKey(true);
        setSearchVal(true);
      }
    }, 2000);
  };


  // handle xiodashboard
  const xioDashboard = () => {
    navigate("/Xiodashboard")
  }

  const checkXioBalance = () => {
    if (balance?.currency !== null) {
      const xioBalance = balance?.currency?.currency?.find(function (obj) {
        return obj.currency === "XIO";
      });
      if (xioBalance) {
        navigate("/Createnft")
      } else {
        toast.error(MessageConst.XIOBALCHECK, {
          toastId: "xioBalCheck"
        });
      }
    }
  }

  return (
    <React.Fragment>
      <Navbar
        collapseOnSelect
        bg="light"
        expand="lg"
        className="nft-header-cst cstmAppNav"
      >
        <Container fluid={true}>
          <Link className="nav-link" to="/">
            <img
              src={FUSIONLOGO}
              className="width-100 logoImg"
              alt="Fuzion-XIO logo"
            />
          </Link>
          {/* <Nav.Link href="/" className="homeNav">Home</Nav.Link> */}
          {!!token ?
            <Dropdown className="homeNav" size="sm">
              <Dropdown.Toggle variant="success" drop="start" id="dropdown-basic">
                Menu
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Link to="/" className="dropdown-item">Home</Link>
                <Link to="/Profile" className="dropdown-item">Profile</Link>
                <Link to="/MyNFT" className="dropdown-item">My NFT's</Link>
                <div className="dropdown-item create-nft" onClick={checkXioBalance}>Create NFT</div>
                <Link to="/Xiodashboard" className="dropdown-item">Validators</Link>
                <Link to="/Vscoredashboard" className="dropdown-item">Verified Profiles</Link>
                <Link to="/ramp" className="dropdown-item">Ramp</Link>
                <Link to="https://www.spatial.io/s/XION-Gallery-64b8c3ba9d0c210a1e8c4d28" target="_blank" className="dropdown-item">XION Gallery</Link>
              </Dropdown.Menu>
            </Dropdown> : <Link to="/" className="home-class">Home</Link>}

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <p className="mb-0 text-left">
                <span className="cardNFTBYACount pr-3 mt-0">
                  MINTED NFT{" "}
                  <span className="totalcount ms-2">{totalMintedNft}</span>
                </span>
                <br />
                <span className="cardNFTBYACount">
                  {" "}
                  TRADED NFT &nbsp;<span className="totalcount">{totalTrade} </span>
                </span>
              </p>
            </Nav>

            <Form className="d-flex searchInput">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2 mb-3 mb-lg-0"
                onChange={(e) => searchItems(e.target.value)}
                aria-label="Search"
              />
            </Form>
            <Nav></Nav>

            {(() => {
              if (token === null) {
                return (
                  <Nav.Link
                    onClick={() => setModalView(true)}
                    className="lgOut11 btn-outline-dark"
                  >
                    Wallet&nbsp;Connect
                  </Nav.Link>
                );
              } else {
                return (
                  <>
                    <div className="btnBOX">
                      <Link className="nav-link d-none d-md-block d-xl-block d-xxl-block" to="/MyNFT">MY NFTs</Link>
                      <div className="nav-link d-none d-md-block d-xl-block d-xxl-block create-nft" onClick={checkXioBalance}>CREATE NFT</div>
                      <Link className="nav-link d-none d-md-block d-xl-block d-xxl-block" to="/ramp" > RAMP </Link>
                      <Link className="nav-link d-none d-md-block d-xl-block d-xxl-block" to="https://www.spatial.io/s/XION-Gallery-64b8c3ba9d0c210a1e8c4d28" target="_blank">XION Gallery</Link>

                      <OverlayTrigger
                        trigger="click"
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Popover id={"popover-positioned-bottom"}>
                            <Popover.Header as="h3">All Notifications</Popover.Header>
                            <Popover.Body>
                              <Row>
                                <Col xs={12} md={12}>
                                  {!!declinedData && declinedData.length > 0 && (
                                    <Nav.Link
                                      href="/DeclinedSendNFT"
                                      className="notification-nav"
                                    >
                                      <span className="notify-text">Sent NFT Declined&nbsp;:&nbsp;<span className="notifybutton">{declinedData.length}</span></span>
                                    </Nav.Link>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} md={12}>
                                  {!!calcelAllPlacedOffer && calcelAllPlacedOffer.length > 0 && (
                                    <Nav.Link
                                      href="/Cancelplacedoffer"
                                      className="notification-nav"
                                    >
                                      <span className="notify-text">Cancel placed offer&nbsp;:&nbsp;<span className="notifybutton">{calcelAllPlacedOffer.length}</span></span>
                                    </Nav.Link>)}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} md={12}>
                                  {!!buyOfferByNftOwner && buyOfferByNftOwner.length > 0 && (
                                    <Nav.Link
                                      href="/BuyOfferReceived"
                                      className="notification-nav"
                                    >
                                      <span className="notify-text">Buy Offer Received&nbsp;:&nbsp;<span className="notifybutton">{buyOfferByNftOwner.length}</span></span>
                                    </Nav.Link>
                                  )}
                                </Col>
                              </Row>

                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <Button className="pe-0" variant=""><i className="fa fa-bell" style={{ fontSize: "24px" }}></i>
                          <span className="badge rounded-pill badge-notification">{!!totalCount && totalCount}</span>
                        </Button>
                      </OverlayTrigger>
                      {/* <Nav.Link className="pe-4 cstmporight" href="/Profile" > <i className="fa fa-user-circle" style={{ fontSize: "24px" }}></i> </Nav.Link> */}
                      <Link className="pe-4 cstmporight nav-link  d-none d-md-block d-xl-block d-xxl-block" to="/Profile" > <i className="fa fa-user-circle" style={{ fontSize: "24px" }}></i> </Link>

                    </div>

                    <OverlayTrigger
                      trigger="click"
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Popover id={"popover-positioned-bottom"}>
                          <Popover.Header align="center" as="h3">
                            Wallet
                          </Popover.Header>
                          <Popover.Body id="pop-body">
                            <Row>
                              <Col xs={12} md={12}>
                                <strong className="text-center">
                                  {myDecodedToken?.ac?.substring(0, 9)}
                                  ****************
                                  {myDecodedToken?.ac?.substring(
                                    myDecodedToken?.ac?.length - 5
                                  )}
                                </strong>
                              </Col>
                            </Row>
                            <br />
                            {currencyBalance?.map((currencyType) => (
                              <Row key={currencyType?.currency}>
                                <Col xs={6} md={6}>
                                  <strong className="text-left">
                                    {currencyType?.currency}
                                  </strong>
                                </Col>
                                <Col xs={6} md={6}>
                                  <strong className="text-right">
                                    {currencyType?.value}
                                  </strong>
                                </Col>
                              </Row>
                            ))}
                          </Popover.Body>
                          <Popover.Body>
                            <Row>
                              <Col xs={12} md={12}>
                                <Button
                                  variant="danger"
                                  className="col-md-12 col-xs-12"
                                  onClick={disconnectWallet}
                                >
                                  Disconnect
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  variant="primary"
                                  className="col-md-12 col-xs-12 vscore-class"
                                  onClick={vScoreDashboard}
                                >
                                  Vscoreboard
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  variant="primary"
                                  className="col-md-12 col-xs-12"
                                  onClick={xioDashboard}
                                >
                                  Xiodashboard
                                </Button>
                              </Col>
                            </Row>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button variant="btn btn-dark connected">
                        Wallet&nbsp;Connected
                      </Button>
                    </OverlayTrigger>
                  </>
                );
              }
            })()}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* // Send Model start // */}
      <SendModel />
      {/* {// Send Model end //} */}
      <ToastContainer />

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        className="qrModal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>{""}</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="padding-modal-image">
            <div className="imgBox">
              <img src={qrCode} alt="" className="qr-img" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center d-md-none d-lg-none d-sm-block">
          <h6 className="only-for-mobile">
            Open in
            <a
              href={`https://xumm.app/sign/${forMobile}`}
              target="_blank"
              rel="noreferrer"
            >
              <span className="see-all-button">XAMAN</span>
            </a>
          </h6>
        </Modal.Footer>
      </Modal>
      {!!searchInput && !searchKey ? (
        <SearchNft data={filteredResults} allMintedNfts={allMintedNfts} />
      ) : (
        <div className={loading ? "parentDisable" : ""}>
          <Spinners.MutatingDots
            visible={loading}
            height="100"
            width="100"
            color="#f531e9"
            secondaryColor="#f531e9"
            radius="12.5"
            wrapperStyle={{
              justifyContent: "center",
            }}
            wrapperClass="search-wrapper"
          />
          {/* <HashLoader
            sizeUnit="px"
            size={100}
            color="#329be3"
            loading={loading}
            cssOverride={override}
          /> */}
        </div>
      )}

      {/* inital wallet show modal */}
      <Modal
        show={modalView}
        onHide={() => setModalView(false)}
        backdrop="static"
        dialogClassName="custom-modal"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="ms-auto">
            {/* <strong>FUSION-XIO</strong> */}
            <img src={FUSIONXIO} height={50} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-md-5 px-lg-5 px-xl-5">
          <h5 className="text-center"><strong>To get Started, Connect your Wallet</strong></h5>
          <div className="md-body">
            <div className="wallet-body"><img src={Xamen} height={40} width={75} />{" "}Xaman <span><Button
              variant="success"
              className="wallet-button"
              // className="BuyNFT-button width-22 sendNFT"
              onClick={connectWallet}
            >
              Connect
            </Button></span></div>
            <div className="wallet-body"><img src={MetaMask} height={40} width={70} />&nbsp;&nbsp;Metamask <span><Button
              variant="success"
              className="wallet-button"
              disabled
            >
              Connect
            </Button></span></div>
            <div className="wallet-body"><img src={WalletConnect} height={40} width={70} />{" "}Wallet Connect <span><Button
              variant="success"
              className="wallet-button"
              disabled
            >
              Connect
            </Button></span></div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Header;
