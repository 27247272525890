import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import { Document, Page, pdfjs } from "react-pdf";
import LazyLoad, { forceVisible } from "react-lazyload";
pdfjs.GlobalWorkerOptions.workerSrc = `cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Filetype(props) {
  const [pageNumber, setNumPages] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if(props.layout){ 
     setTimeout(() => {
         forceVisible();
     }, 2500)
    }
   }, [props.layout])

  return (
    <>
      {(() => {
        if (props.fileType === "image" || props.fileType === "gif") {
          return (
            <LazyLoad
              height={200}
              placeholder={
                <div className="three-body">
                  <div className="three-body__dot"></div>
                  <div className="three-body__dot"></div>
                  <div className="three-body__dot"></div>
                </div>
              }
              offset={100}
            >
              <div className="main-img">
                <Card.Img
                  variant="top"
                  src={props.image}
                  className="min-height-200-new imgRadius mb-md-3"
                />

                {!!props.profileImg && <div className="img-box-home">
                  <div className="proFile">
                    <img
                      src={props.profileImg}
                      alt=""
                      className={`inner-ing ${props.image}`}
                    />
                  </div>
                </div>}
              </div>
            </LazyLoad>
          );
        } else if (props.fileType === "video") {
          return (
            <>
              <LazyLoad
                height={200}
                offset={100}
                placeholder={
                  <div className="three-body">
                    <div className="three-body__dot"></div>
                    <div className="three-body__dot"></div>
                    <div className="three-body__dot"></div>
                  </div>
                }
              >
                <div className="main-img">
                  <div className="video-tag">
                    <video
                      className="video-player"
                      preload="metadata"
                      controls
                      autoPlay
                      muted
                      playsInline
                      controlsList="nodownload"
                    >
                      <source src={props.image + "#t=0.2"} />
                    </video>
                  </div>

                  {!!props.profileImg && <div className="img-box-home">
                  <div className="proFile">
                    <img
                      src={props.profileImg}
                      alt=""
                      className={`inner-ing ${props.image}`}
                    />
                  </div>
                </div>}
                </div>
              </LazyLoad>
            </>
          );
        } else if (props.fileType === "audio") {
          return (
            <>
              <LazyLoad
                height={200}
                offset={100}
                placeholder={
                  <div className="three-body">
                    <div className="three-body__dot"></div>
                    <div className="three-body__dot"></div>
                    <div className="three-body__dot"></div>
                  </div>
                }
              >
                <div className="main-img">
                  <div className="audio-video-tag home-music audio-tag">
                    <audio controls controlsList="nodownload">
                      <source src={props.image} />
                    </audio>
                  </div>

                  {!!props.profileImg && <div className="img-box-home">
                  <div className="proFile">
                    <img
                      src={props.profileImg }
                      alt=""
                      className={`inner-ing ${props.image}`}
                    />
                  </div>
                </div>}
                </div>
              </LazyLoad>
            </>
          );
        } else if (props.fileType === "application") {
          return (
            <LazyLoad
              height={200}
              offset={100}
              placeholder={
                <div className="three-body">
                  <div className="three-body__dot"></div>
                  <div className="three-body__dot"></div>
                  <div className="three-body__dot"></div>
                </div>
              }
            >
              <div className="pdf-style mb-md-3 mb-lg-3">
                <Document
                  className="pdf-style pdf-card"
                  file={props.image}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} className="pdf-style" />
                </Document>

                {!!props.profileImg && <div className="img-box-home">
                  <div className="proFile">
                    <img
                      src={props.profileImg}
                      alt=""
                      className={`inner-ing ${props.image}`}
                    />
                  </div>
                </div>}
              </div>
            </LazyLoad>
          );
        }
      })()}
    </>
  );
}

export default Filetype;
