import configData from "../config.json";

export const uniqueArray = (arr, wallet) => {
  const res = Object.values(
    arr.filter(vl => vl.collectionName !== "").reduce((acc, current) => {
      (acc[current.collectionName.trim()] = acc[current.collectionName.trim()] || []).push(
        current
      );
      return acc;
    }, {})
  );

  const Arr = [];
  res.forEach((vl) => {
    if (vl.find(({ accountNumber }) => accountNumber === wallet)) {
      if (vl.some(v1 => v1.isPurchased === 0)) {
        if (vl.find(v2 => v2.IssuerAddr === wallet)) {
          Arr.push(vl.find(({ isPurchased }) => isPurchased === 0));
        } else if (vl.find(v2 => v2.IssuerAddr === null)) {
          Arr.push(vl.find(({ isPurchased }) => isPurchased === 0));
        }
      }
      if (vl.some(v1 => v1.isPurchased === 1)) {
        Arr.push(vl.find((({ isPurchased }) => isPurchased === 1)));
      }
    } else if (vl.some(({ accountNumber }) => accountNumber !== wallet)) {
      if (vl.some(({ isPurchased }) => isPurchased === 0)) {
        Arr.push(vl.find(({ isPurchased }) => isPurchased === 0));
      } else if (vl.some(({ isPurchased }) => isPurchased === 1)) {
        Arr.push(vl.find(({ isPurchased }) => isPurchased === 1));
      }
    }
  });

  console.log(Arr, "resp in data")

  return Arr.filter(vl => vl);
}

export const replaceHost = (url) => {
  const CID = url.split("/").pop();
  const finalUrl = `${configData.ipfs_p}${CID}`;
  return finalUrl
}