export const actionTypes = {
	WALLET_CONNECT_REQUEST: 'WALLET_CONNECT_REQUEST',
	WALLET_CONNECT_SUCCESS: 'WALLET_CONNECT_SUCCESS',
	WALLET_CONNECT_FAILURE: 'WALLET_CONNECT_FAILURE',


	ACCOUNT_DETAIL_REQUEST: 'ACCOUNT_DETAIL_REQUEST',
	ACCOUNT_DETAIL_SUCCESS: 'ACCOUNT_DETAIL_SUCCESS',
	ACCOUNT_DETAIL_FAILURE: 'ACCOUNT_DETAIL_FAILURE',

	GET_BALANCE_REQUEST: 'GET_BALANCE_REQUEST',
	GET_BALANCE_SUCCESS: 'GET_BALANCE_SUCCESS',
	GET_BALANCE_FAILURE: 'GET_BALANCE_FAILURE',

	REGISTRATION_FEE_REQUEST: 'REGISTRATION_FEE_REQUEST',
	REGISTRATION_FEE_SUCCESS: 'REGISTRATION_FEE_SUCCESS',
	REGISTRATION_FEE_FAILURE: 'REGISTRATION_FEE_FAILURE',

	WALLET_DISCONNECT_REQUEST: 'WALLET_DISCONNECT_REQUEST',
	WALLET_DISCONNECT_SUCCESS: 'WALLET_DISCONNECT_SUCCESS',
	WALLET_DISCONNECT_FAILURE: 'WALLET_DISCONNECT_FAILURE',


	CREATE_PAYMENT_REQUEST: 'CREATE_PAYMENT_REQUEST',
	CREATE_PAYMENT_SUCCESS: 'CREATE_PAYMENT_SUCCESS',
	CREATE_PAYMENT_FAILURE: 'CREATE_PAYMENT_FAILURE'

};