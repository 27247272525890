import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Card } from "react-bootstrap";
import DummyProfile from "../../assets/defaultpimage.jpg"
import { Link } from "react-router-dom";
import Filetype from "../common/Filetype";
import { getProfileDetails } from "../../helper/getProfileDetails"
import tokenbadge from '../../../src/assets/tokenimg.png';
import * as Spinners from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import configData from "../../config.json";
import { decodeToken } from "react-jwt";
import Pagination from "react-bootstrap/Pagination";
import Header from "../common/header";

import Like from "./like/like";
import MessageConst from "../../const/message.json";
import { profileBatchColor } from "../../helper/getProfileDetails"

import {
    getProfileAction,
    getProfileVScoreAction
} from "../../store/actions/profile";

import { getNftsByCollection } from "../../store/actions/nftdetail";
import Footer from '../common/footer';
import { replaceHost } from '../../helper';

const Collections = () => {

    const dispatch = useDispatch();
    const token = localStorage.getItem("jwtToken");
    const myDecodedToken = decodeToken(token);


    const [searchKey, setSearchKey] = useState(true);
    const [collections, setCollections] = useState(null);
    const [layout, setLayout] = useState(false);
    const [allProfile, setAllProfile] = useState(null)
    const [allMintedNfts, setAllMintedNfts] = useState(null);
    const [page, setPage] = useState(1);
    const [searchParams] = useSearchParams();

    const [homedtl, likeStatus] = useSelector((state) => [
        state.homeDetailReducer,
        state.nftLikeReducer,
    ]);

    const handlePagination = (e) => {
        setPage(e);
    };


    const getVScore = (NFTokenID) => {
        const IssuerToken = allMintedNfts?.find((vl) => vl.NFTokenID === NFTokenID);
        dispatch(getProfileVScoreAction({ wAddress: IssuerToken?.Issuer }))
            .then((vScorePoint) => {
                if (vScorePoint) {
                    const { vPointDetails } = vScorePoint?.data;
                    return vPointDetails[0]?.totalVPoint;
                }
            }).catch((err) => console.log(err, "vpoint error"))
    }

    useEffect(() => {
        dispatch(getProfileAction({ wAddress: "" })).then((pDetail) => {
            setAllProfile(pDetail.data.allProfile);
        }).catch((err) => console.log(err, "pdetails error"));
    }, []); // eslint-disable-line

    useEffect(() => {
        if (homedtl.nftDetail !== "") {
            const { allMintedNft } =
                homedtl.nftDetail;
            setAllMintedNfts(allMintedNft);
        }
    }, [homedtl]); // eslint-disable-line


    useEffect(() => {        
        console.log(atob(searchParams.get('name')), "check the collection name *******************************", searchParams.get('address'))
        dispatch(getNftsByCollection({ page, collectionName: atob(searchParams.get('name')), wltAddress: searchParams.get('address') })).then((resp) => {
            const { success } = resp.data;
            if (success) {
                setCollections(resp.data.data)
            }
        });
    }, [page, likeStatus]);

    return (<React.Fragment>
        <Header setSearchKey={setSearchKey} />
        {searchKey && (
            <div className={`gradientBg ${layout ? "myNFT" : ""}`}>
                <br /><br />
                <Container className="container">
                    <Row className="auth-wrapper ownNftSection m-0">
                        <Col xs={12} md={12}>
                            {!collections ? <Row className="loader-class">
                                <Spinners.MutatingDots
                                    visible={true}
                                    height="100"
                                    width="100"
                                    color="#f531e9"
                                    secondaryColor="#f531e9"
                                    radius="12.5"
                                    wrapperStyle={{
                                        justifyContent: "center",
                                    }}
                                />
                            </Row> :
                                collections?.docs?.length === 0 ? (
                                    <Row>
                                        <p className="text-center"> {MessageConst.NoDataFound}</p>
                                    </Row>
                                ) : (
                                    <Row className="ownNft m-md-0">
                                        {collections?.docs?.sort((a, b) => parseInt(a.name.split("#")[1]) > parseInt(b.name.split("#")[1]) ? 1 : -1).map((post) => (
                                            <Col
                                                lg={4}
                                                md={6}
                                                xs={layout ? 4 : 12}
                                                key={post._id}
                                                className={``}
                                            >
                                                <Card
                                                    className={`${post.status} ${layout ? "p-md-3 " : "p-3"
                                                        } customShado mb-3 mb-lg-5 mb-md-5 bg-white rounded shadowcstm corner-ribbon profile-ribbon`}
                                                >

                                                    <div className="top-left-view">
                                                        <img alt="" src={allProfile?.find(vl => vl.wAddress === post?.accountNumber)?.pImage === null ? DummyProfile : allProfile?.find(vl => vl.wAddress === post?.accountNumber)?.pImage?.startsWith("https://ipfs") ? replaceHost(allProfile?.find(vl => vl.wAddress === post?.accountNumber)?.pImage) : `${configData.LOCAL_API_URL + allProfile?.find(vl => vl.wAddress === post?.accountNumber)?.pImage}`} className="img-circle-view" /><p className="top-left-text">{myDecodedToken !== null ? <Link to={"/Profile/" + post?.accountNumber}>
                                                            {post?.accountNumber.substring(0, 9)} *****{" "}
                                                            {post?.accountNumber.substring(
                                                                post?.accountNumber.length - 5
                                                            )}
                                                        </Link>
                                                            : `${post?.accountNumber.substring(0, 9)} ***** ${post?.accountNumber.substring(
                                                                post?.accountNumber.length - 5
                                                            )}`}</p></div>


                                                    <div className="card-img-overlay-custome likeCount">
                                                        <div>
                                                            <p className="d-flex align-items-center">
                                                                <Like
                                                                    post={post?.likes_data}
                                                                    id={post?._id}
                                                                />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <Link
                                                        className="onwfilea profile-nft"
                                                        to={"../Nftdetail/" + post._id}
                                                        state={{ isValid: post?.accountNumber === searchParams.get('address') }}
                                                    >
                                                        <div className="onwfilea profile-nft">
                                                            <Filetype
                                                                fileType={post.contentType}
                                                                image={replaceHost(post.image)}
                                                                layout={layout}
                                                                profileImg={getProfileDetails(allProfile, allMintedNfts, post.NFTokenID)?.pImage ? getProfileDetails(allProfile, allMintedNfts, post.NFTokenID).pImage.startsWith("https://ipfs") ? replaceHost(getProfileDetails(allProfile, allMintedNfts, post.NFTokenID)?.pImage) : getProfileDetails(allProfile, allMintedNfts, post.NFTokenID).pImage.startsWith("uploads/") ? `${configData.LOCAL_API_URL + getProfileDetails(allProfile, allMintedNfts, post.NFTokenID)?.pImage}` : DummyProfile : DummyProfile}
                                                            />
                                                        </div>
                                                    </Link>

                                                    <Card.Body>
                                                        <div className="body-card">
                                                            <Card.Title className="cardNFTName">
                                                                {post.name
                                                                    ? post?.name?.length > 40
                                                                        ? post?.name?.substring(0, 40) + "..."
                                                                        : post?.name
                                                                    : "NA"}
                                                            </Card.Title>
                                                            <p className="body-cart-para">
                                                                {(() => {
                                                                    const IssuerToken = allMintedNfts?.find(
                                                                        (vl) =>
                                                                            vl.NFTokenID === post.NFTokenID
                                                                    );
                                                                    if (IssuerToken?.Issuer !== undefined) {
                                                                        if (myDecodedToken !== null) {
                                                                            return <Link to={"/Profile/" + IssuerToken?.Issuer}>{IssuerToken.Issuer.substring(0, 9) + " ***** " + IssuerToken.Issuer.substring(IssuerToken.Issuer.length - 5)}</Link>
                                                                        } else {
                                                                            return IssuerToken.Issuer.substring(0, 9) + " ***** " + IssuerToken.Issuer.substring(IssuerToken.Issuer.length - 5)
                                                                        }
                                                                    } else {
                                                                        return <span className="issuer-cls">N/A</span>;
                                                                    }
                                                                })()}
                                                                <div className="profile-collection token-badge customTokenbadge">
                                                                    <img src={tokenbadge} alt="" />
                                                                </div>
                                                                <div className="profile-cls tick-badge customTickbadge">
                                                                    {profileBatchColor(getVScore(post.NFTokenID))}
                                                                </div>
                                                            </p>
                                                            <hr className="hr-cls" />
                                                            <p className="cardNFTBYACount">
                                                                {post.currency ? `${post.currency}` : "XRP"}
                                                            </p>
                                                            <p className="cardNFTBY">
                                                                {post?.price}
                                                            </p>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                )}
                            <br />
                            <Row className="mx-auto">
                                <Col md={12} xs={12} className="text-right">
                                    {!!collections && collections.totalPages > 1 && (
                                        <Pagination>
                                            {collections?.hasPrevPage ? (
                                                <Pagination.First onClick={() => handlePagination(1)} />
                                            ) : (
                                                <Pagination.First disabled />
                                            )}
                                            {collections?.prevPage !== null ? (
                                                <Pagination.Prev
                                                    onClick={() => handlePagination(collections?.prevPage)}
                                                />
                                            ) : (
                                                <Pagination.Prev disabled />
                                            )}

                                            {Array.from(Array(collections?.totalPages).keys()).map(
                                                (i, index) => {
                                                    return (
                                                        <Pagination.Item
                                                            key={index}
                                                            className={page === i + 1 ? "active" : ""}
                                                            onClick={() => handlePagination(i + 1)}
                                                        >
                                                            {i + 1}
                                                        </Pagination.Item>
                                                    );
                                                }
                                            )}
                                            {collections?.nextPage !== null ? (
                                                <Pagination.Next
                                                    onClick={() => handlePagination(collections?.nextPage)}
                                                />
                                            ) : (
                                                <Pagination.Next disabled />
                                            )}
                                            {collections?.totalPages === page ? (
                                                <Pagination.Last disabled />
                                            ) : (
                                                <Pagination.Last
                                                    onClick={() => handlePagination(collections?.totalPages)}
                                                />
                                            )}
                                        </Pagination>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>)
        }
        <Footer />
    </React.Fragment>
    )
}

export default Collections;