import "./App.css";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useJwt } from "react-jwt";
import Nft from "./components/nft/nft";
import Createnft from "./components/nft/Createnft";
import Ownnft from "./components/nft/ownnft";
import Nftdetail from "./components/nft/Nftdetail";
import Nftlist from "./components/nft/Nftlist";
import BidDetail from "./components/nft/biddetail";
import Searchnft from "./components/nft/searchNft";
import InteroperabilityDetail from "./components/nft/interoperabilityDetail";
import DeclinedSendNFT from "./components/nft/declinedSendNFT";
import Ramp from "./components/ramp/Ramp";
import CancelPlacedOffer from "./components/nft/cancelPlacedOffer";
import BuyOfferReceived from "./components/nft/buyOfferReceived";
import Profile from "./components/nft/profile";
import VscoreDashboard from "./components/nft/vScoreDashboard";
import Collections from "./components/nft/collections";
import XioDashboard from "./components/nft/xioDashboard";

function App() {
	const token = localStorage.getItem("jwtToken");
	const { isExpired } = useJwt(token);

	const PrivateRoute = () => {
		return (
			token !== null && isExpired === false ? <Outlet /> : <Navigate to='/' />
		)
	}


	return (
		<Router>
			{/* <Suspense fallback={<Spinners.MutatingDots
				visible={true}
				height="100"
				width="100"
				color="#f531e9"
				secondaryColor="#f531e9"
				radius="12.5"
				wrapperStyle={{
					justifyContent: "center",
				}}
			/>}> */}
				<Routes>
					<Route path="/" element={<Nft />} />
					<Route path="/Nftdetail/:id" element={<Nftdetail />} />
					<Route
						path="/InteroperabilityDetail/:id"
						element={<InteroperabilityDetail />}
					/>
					<Route path="/Nftlist/:type" element={<Nftlist />} />
					<Route path="/BidDetail/:id" element={<BidDetail />} />
					<Route path="/Searchnft" element={<Searchnft />} />
					<Route path="/Profile/:id" element={<Profile />} />
					<Route path="/collections" element={<Collections />} />


					<Route element={<PrivateRoute />} >
						<Route path="/Createnft" element={<Createnft />} />
						<Route path="/MyNFT" element={<Ownnft />} />
						<Route path="/DeclinedSendNFT" element={<DeclinedSendNFT />} />
						<Route path='/ramp' element={<Ramp />} />
						<Route path="/Cancelplacedoffer" element={<CancelPlacedOffer />} />
						<Route path="/BuyOfferReceived" element={<BuyOfferReceived />} />
						<Route path="/Profile" element={<Profile />} />
						<Route path="/Vscoredashboard" element={<VscoreDashboard />} />
						<Route path="/Xiodashboard" element={<XioDashboard />} />
					</Route>

					<Route path="*" element={<Navigate to='/' />} />
				</Routes>
			{/* </Suspense> */}
		</Router>
	);
}

export default App;
