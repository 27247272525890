export const actionTypes = {
	NFTSBY_ADDRESS_REQUEST: 'NFTSBY_ADDRESS_REQUEST',
	NFTSBY_ADDRESS_SUCCESS: 'NFTSBY_ADDRESS_SUCCESS',
	NFTSBY_ADDRESS_FAILURE: 'NFTSBY_ADDRESS_FAILURE',

	BURNNFTINT_REQUEST:'BURNNFTINT_REQUEST',
	BURNNFTINT_SUCCESS:'BURNNFTINT_SUCCESS',
	BURNNFTINT_FAILURE:'BURNNFTINT_FAILURE',

	SALENFTINT_REQUEST:'SALENFTINT_REQUEST',
	SALENFTINT_SUCCESS:'SALENFTINT_SUCCESS',
	SALENFTINT_FAILURE:'SALENFTINT_FAILURE',

	CANCELSALENFTINT_REQUEST:'CANCELSALENFTINT_REQUEST',
	CANCELSALENFTINT_SUCCESS:'CANCELSALENFTINT_SUCCESS',
	CANCELSALENFTINT_FAILURE:'CANCELSALENFTINT_FAILURE',

	BUYNFTINT_REQUEST:'BUYNFTINT_REQUEST',
	BUYNFTINT_SUCCESS:'BUYNFTINT_SUCCESS',
	BUYNFTINT_FAILURE:'BUYNFTINT_FAILURE',

	SENDNFTINT_REQUEST:"SENDNFTINT_REQUEST",
	SENDNFTINT_SUCCESS:"SENDNFTINT_SUCCESS",
	SENDNFTINT_FAILURE:"SENDNFTINT_FAILURE"
};