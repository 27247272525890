export const actionTypes = {
	SINGLE_NFT_DETAIL_REQUEST: 'SINGLE_NFT_DETAIL_REQUEST',
	SINGLE_NFT_DETAIL_SUCCESS: 'SINGLE_NFT_DETAIL_SUCCESS',
	SINGLE_NFT_DETAIL_FAILURE: 'SINGLE_NFT_DETAIL_FAILURE',

	TRADEHISTORY_REQUEST:'TRADEHISTORY_REQUEST',
	TRADEHISTORY_SUCCESS:'TRADEHISTORY_SUCCESS',
	TRADEHISTORY_FAILURE:'TRADEHISTORY_FAILURE',

	UPDATENFTINFO_REQUEST:"UPDATENFTINFO_REQUEST",
	UPDATENFTINFO_SUCCESS:"UPDATENFTINFO_SUCCESS",
	UPDATENFTINFO_FAILURE:"UPDATENFTINFO_FAILURE",

	ADDCOLLECTION_REQUEST:"ADDCOLLECTION_REQUEST",
	ADDCOLLECTION_SUCCESS:"ADDCOLLECTION_SUCCESS",
	ADDCOLLECTION_FAILURE:"ADDCOLLECTION_FAILURE",

	GETCOLLECTION_REQUEST:"GETCOLLECTION_REQUEST",
	GETCOLLECTION_SUCCESS:"GETCOLLECTION_SUCCESS",
	GETCOLLECTION_FAILURE:"GETCOLLECTION_FAILURE",

	DELETECOLLECTION_REQUEST:"DELETECOLLECTION_REQUEST",
	DELETECOLLECTION_SUCCESS:"DELETECOLLECTION_SUCCESS",
	DELETECOLLECTION_FAILURE:"DELETECOLLECTION_FAILURE"
};