import React from "react";
import website from "../../assets/website.svg";
import Button from "react-bootstrap/Button";



const SocialSitesLink = ({ socialLink, setshowdialog, validateProf }) => {
    // console.log(socialLink, "socialLink")
    return (
        <>
         <div className="socialbox">
                    <ul>           
                      {!!socialLink && socialLink.socialLinks.length > 0 ? 
                       (socialLink.socialLinks.map((vl, i) => {
                           return (
                          <>
                            <li key={i}>
                              <a
                                href={vl.twitter}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {/* <i className="fa fa-twitter" aria-hidden="true"></i> */}
                                <i className="fa-brands fa-x-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href={vl.linkedIn}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                <i
                                  className="fa fa-linkedin"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href={vl.youtube}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                <i
                                  className="fa fa-youtube-play"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href={vl.facebook}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                <i
                                  className="fa fa-facebook"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href={vl.telegram}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i
                                  className="fa fa-telegram"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href={vl.website}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src={website} alt="" width={35} />
                              </a>
                            </li>
                            <li>
                              <a
                                href={vl.discord}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i
                                  className="fab fa-discord"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li>
                          </>
                       )})): (
                            <>
                            <li>
                                <i className="fa-brands fa-x-twitter"></i>
                            </li>
                            <li>
                                <i
                                  className="fa fa-linkedin"
                                  aria-hidden="true"
                                ></i>
                            </li>
                            <li>
                                <i
                                  className="fa fa-youtube-play"
                                  aria-hidden="true"
                                ></i>
                            </li>
                            <li>
                              
                                <i
                                  className="fa fa-facebook"
                                  aria-hidden="true"
                                ></i>
                            </li>
                            <li>
                              
                                <i
                                  className="fa fa-telegram"
                                  aria-hidden="true"
                                ></i>
                            </li>
                            <li>
                                <img src={website} alt="" width={35} />
                            </li>
                            <li>
                                <i
                                  className="fab fa-discord"
                                  aria-hidden="true"
                                ></i>
                            </li>
                          </>
                        )}
                    </ul>
                    {validateProf && <Button
                      className="editSocial"
                      onClick={() => setshowdialog(true)}
                    >
                      <i className="fa fa-pencil" aria-hidden="true"></i>
                    </Button>}
                  </div>
        </>
    )
}

export default SocialSitesLink;
