import axios from "axios";
import config from "../../config.json";
const token = localStorage.getItem("jwtToken");

//////////// QR CODE STRAT///////
export const walletConnect = async (data) => {
	const walletCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xumm/connect",
	};

	try {
		const wallet = await axios(walletCofig);
		if (wallet.status === 200) {
			return wallet;
		}
	} catch (e) {		
		return e.response;
	}
};
//////////// QR CODE END///////
//////////// ACCOUNT DETAIL STRAT///////
export const accountDetail = async (data) => {
	const uuidCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xumm/accountDetail",
	};

	try {
		const accountDetail = await axios(uuidCofig);
		if (accountDetail.status === 200) {
			return accountDetail;
		}
	} catch (e) {
		return e.response;
	}
};
//////////// ACCOUNT DETAIL END///////

//////////// GET BALANCE STRAT///////
export const getBalanceDetail = async (data) => {
	const getBalanceCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xumm/getBalance",
	};

	try {
		const balance = await axios(getBalanceCofig);
		if (balance.status === 200) {
			return balance;
		}
	} catch (e) {
		return e.response;
	}
};
//////////// GET BALANCE END///////

//////////// GET registrationFee///////
export const registrationFeeApi = async (data) => {
	const getBalanceCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xumm/registrationFee",
	};

	try {
		const balance = await axios(getBalanceCofig);
		if (balance.status === 200) {
			return balance;
		}
	} catch (e) {
		return e.response;
	}
};
//////////// GET registrationFee///////

//////////// DIS CONNECT STRAT///////
export const walletDisConnect = async (data) => {
	const walletDisCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xumm/disConnect",
	};

	try {
		const disCon = await axios(walletDisCofig);
		if (disCon.status === 200) {
			return disCon;
		}
	} catch (e) {
		return e.response;
	}
};
//////////// DIS CONNECT END///////


// Create payment before create an NFT

export const createPayment = async (data) => {
	const paymentConfig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "nft/createPayment",
	};

	try {
		const payment = await axios(paymentConfig);
		if (payment.status === 200) {
			return payment;
		}
	} catch (e) {
		return e.response;
	}
};