import axios from "axios";
import config from "../../config.json";
const token = localStorage.getItem("jwtToken");


////////////////// MINT NFT DATA ///////////////////////////

export const mintNft = async (data) => {
	const mintCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/mintNft",
	};
	try {
		const mintNftData = await axios(mintCofig);
		if (mintNftData.status === 200) {
			return mintNftData;
		}
	} catch (e) {
		return e.response;
	}
};

///////////////////// BURN NFT DATA ///////////////////////

export const burnNft = async (data) => {
	const burnCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/burnNft",
	};
	try {
		const burnNftData = await axios(burnCofig);
		if (burnNftData.status === 200) {
			return burnNftData;
		}
	} catch (e) {
		return e.response;
	}
};

///////////////////// SALE NFT DATA ///////////////////////

export const saleNft = async (data) => {
	const saleCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/saleNft",
	};
	try {
		const saleNftData = await axios(saleCofig);

		if (saleNftData.status === 200) {
			return saleNftData;
		}
	} catch (e) {
		return e.response;
	}
};

///////////////////// CANCEL SALE NFT DATA ///////////////////////

export const cancelSaleNft = async (data) => {
	const cancelSaleCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/cancelSaleNft",
	};
	try {
		const cancelSaleNftData = await axios(cancelSaleCofig);
		if (cancelSaleNftData.status === 200) {
			return cancelSaleNftData;
		}
	} catch (e) {
		return e.response;
	}
};


///////////////////// BUY NFT DATA ///////////////////////

export const buyNft = async (data) => {
	const buyCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/buyNft",
	};
	try {
		const buyNftData = await axios(buyCofig);
		if (buyNftData.status === 200) {
			return buyNftData;
		}
	} catch (e) {
		return e.response;
	}
};


///////////////////// BID NFT DATA ///////////////////////

export const bidToken = async (data) => {
	const bidCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/bidNft",
	};
	try {
		const bidNftData = await axios(bidCofig);
		if (bidNftData.status === 200) {
			return bidNftData;
		}
	} catch (e) {
		return e.response;
	}
};

///////////////////// CANCEL BID NFT DATA ///////////////////////

export const bidCancelToken = async (data) => {
	const bidCancelCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/bidCancel",
	};
	try {
		const bidCancelNftData = await axios(bidCancelCofig);
		if (bidCancelNftData.status === 200) {
			return bidCancelNftData;
		}
	} catch (e) {
		return e.response;
	}
};

/////////////////////  BID AND BURN NFT DATA ///////////////////////

export const bidAndBurnToken = async (data) => {
	const bidAndBurnCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/bidAndBurn",
	};
	try {
		const bidAndBurnNftData = await axios(bidAndBurnCofig);
		if (bidAndBurnNftData.status === 200) {
			return bidAndBurnNftData;
		}
	} catch (e) {
		return e.response;
	}
};


//==============Total Minted Nft start=================

export const allMintedNft = async () => {
	const walletDisCofig = {
		baseURL: config.LOCAL_API_URL,
		method: "get",
		url: "xrpl/allMintedNft",
	};
	try {
		const allMintedNft = await axios(walletDisCofig);
		if (allMintedNft.status === 200) {
			return allMintedNft;
		}
	} catch (e) {
		return e.response;
	}
};

// ==================Delete NFT api call service===============

export const deleteNft = async (data) => {
	const deleteNftCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "delete",
		url: "nft/deleteNft",
	};
	try {
		const deltedNft = await axios(deleteNftCofig);
		if (deltedNft.status === 200) {
			return deltedNft;
		}
	} catch (e) {
		return e.response;
	}
};

export const totalTrade = async () => {
	const walletDisCofig = {
		baseURL: config.LOCAL_API_URL,
		method: "get",
		url: "nft/totalTradeHistory",
	};
	try {
		const totalTradeHistory = await axios(walletDisCofig);
		if (totalTradeHistory.status === 200) {
			return totalTradeHistory;
		}
	} catch (e) {
		return e.response;
	}
};


///////////////////// SEND NFT DATA ///////////////////////

export const sendNft = async (data) => {
	const sendCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/sendNft",
	};
	try {
		const sendNftData = await axios(sendCofig);
		if (sendNftData.status === 200) {
			return sendNftData;
		}
	} catch (e) {
		return e.response;
	}
};

///////////////////// RECEIVE NFT DATA ///////////////////////

export const receiveNft = async (data) => {
	const receiveCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/receiveNft",
	};

	try {
		const receiveNftData = await axios(receiveCofig);
		if (receiveNftData.status === 200) {
			return receiveNftData;
		}
	} catch (e) {
		return e.response;
	}
};

/* CANCEL SEND NFT */

export const cancelSendNft = async (data) => {
	const cancelSendCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/cancelSendNft",
	};
	try {
		const cancelSendNftData = await axios(cancelSendCofig);
		if (cancelSendNftData.status === 200) {
			return cancelSendNftData;
		}
	} catch (e) {
		return e.response;
	}
};

/* Place more offers api call */
export const placeMoreOffer = async (data) => {
	const placeMoreofferCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/MakeOffer",
	};
	try {
		const placeMoreOfferData = await axios(placeMoreofferCofig);
		if (placeMoreOfferData.status === 200) {
			return placeMoreOfferData;
		}
	} catch (e) {
		return e.response;
	}
};

/* Get all placed offers api call */

export const getAllPlacedOffers = async (data) => {
	const getAllPlacedOffersCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data: { _id: data},
		method: "post",
		url: "xrpl/getAllOffers",
	};
	try {
		const getAllPlacedOffersData = await axios(getAllPlacedOffersCofig);
		if (getAllPlacedOffersData.status === 200) {
			return getAllPlacedOffersData;
		}
	} catch (e) {
		return e.response;
	}
};

/*Accept placed offer api call */
export const acceptPlacedOffer = async(data) =>{
	const acceptPlacedOffersCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
	    data,
		method: "post",
		url: "xrpl/acceptPlacedOffer",
	};
	try {
		const acceptPlacedOffersData = await axios(acceptPlacedOffersCofig);
		if (acceptPlacedOffersData.status === 200) {
			return acceptPlacedOffersData;
		}
	} catch (e) {
		return e.response;
	}
}

/*All placed offer to be cancelled api call */
export const allCancelPlacedOffer = async() =>{
	const allCancelPlacedOfferCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		method: "post",
		url: "xrpl/getAllCancelPlacedOffer",
	};
	try {
		const allCancelPlacedOfferData = await axios(allCancelPlacedOfferCofig);
		if (allCancelPlacedOfferData.status === 200) {
			return allCancelPlacedOfferData;
		}
	} catch (e) {
		return e.response;
	}
}

/*Cancel placed offer api call */
export const cancelPlacedOffer = async(data) =>{
	const cancelPlacedOfferCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "xrpl/cancelPlaceOffer",
	};
	try {
		const cancelPlacedOffer = await axios(cancelPlacedOfferCofig);
		if (cancelPlacedOffer.status === 200) {
			return cancelPlacedOffer;
		}
	} catch (e) {
		return e.response;
	}
}

/*All offers by Nft Owner */
export const allOfferByNftOwner = async() =>{
	const allOfferByNftOwnerCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		method: "post",
		url: "xrpl/getOffersByNftOwner",
	};
	try {
		const allOfferDataByNftOwner = await axios(allOfferByNftOwnerCofig);
		if (allOfferDataByNftOwner.status === 200) {
			return allOfferDataByNftOwner;
		}
	} catch (e) {
		return e.response;
	}
}