
/* Profile action types */
export const actionTypes = {
	CREATE_PROFILE_REQUEST: 'CREATE_PROFILE_REQUEST',
	CREATE_PROFILE_SUCCESS: 'CREATE_PROFILE_SUCCESS',
	CREATE_PROFILE_FAILURE: 'CREATE_PROFILE_FAILURE',

	VALIDATE_PROFILE_REQUEST:"VALIDATE_PROFILE_REQUEST",
	VALIDATE_PROFILE_SUCCESS:"VALIDATE_PROFILE_SUCCESS",
	VALIDATE_PROFILE_FAILURE:"VALIDATE_PROFILE_FAILURE",

	RESET_PROFILE_DATA:"RESET_PROFILE_DATA"
};