const fileArr = [
	"image/png",
	"image/gif",
	"image/jpeg",
	"audio/mpeg",
	"audio/ogg",
	"video/ogg",
	"video/mp4",
	"application/pdf",
	"video/quicktime",
	"video/x-matroska",
];

export default fileArr;