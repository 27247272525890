import axios from "axios";
import config from "../../config.json";
const token = localStorage.getItem("jwtToken");


/* create profile api call */
export const createProfile = async (data) => {
	const createProfileCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "profile/createprofile",
	};
	try {
		const createProfileData = await axios(createProfileCofig);
		if (createProfileData.status === 200) {
			return createProfileData;
		}
	} catch (e) {
		return e.response;
	}
};

/*Get profile details */
export const getProfile = async (data) => {
	const getProfileCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "profile/getProfile",
	};
	try {
		const getProfileData = await axios(getProfileCofig);
		if (getProfileData.status === 200) {
			return getProfileData;
		}
	} catch (e) {
		return e.response;
	}
};


/*Get profile XIO balance and validator level details */
export const getBalAndLevel = async (data) => {
	const getProfileCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "profile/getbalanceandlevel",
	};
	try {
		const getProfileData = await axios(getProfileCofig);
		if (getProfileData.status === 200) {
			return getProfileData;
		}
	} catch (e) {
		return e.response;
	}
};

/* validate profile api call */
export const validateProfile = async (data) => {
	// console.log("=service===",data);
	const createProfileCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "profile/verifyprofile",
	};
	try {
		const createProfileData = await axios(createProfileCofig);
		if (createProfileData.status === 200) {
			return createProfileData;
		}
	} catch (e) {
		console.log(e.response, "check the profile error")
		return e.response;
	}
};

/*Get profile vpoint */
export const getProfileVScore = async (data) => {
	const getProfileVScoreCofig = {
		baseURL: config.LOCAL_API_URL,
		headers: {
			Authorization: `Basic ${token}`,
		},
		data,
		method: "post",
		url: "profile/getvpoint",
	};
	try {
		const getProfileVScore = await axios(getProfileVScoreCofig);
		if (getProfileVScore.status === 200) {
			return getProfileVScore;
		}
	} catch (e) {
		return e.response;
	}
};

/*Get profile vscoreDashboard */
export const getVScoreDashboard = async (data) => {
	const getVScoreDashboardCofig = {
	  baseURL: config.LOCAL_API_URL,
	  headers: {
		Authorization: `Basic ${token}`,
	  },
	  data,
	  method: "get",
	  url: "profile/scoreboardvpoint",
	};
	try {
	  const getVScoreDashboard = await axios(getVScoreDashboardCofig);
	  if (getVScoreDashboard.status === 200) {
		return getVScoreDashboard;
	  }
	} catch (e) {
	  return e.response;
	}
  };

/*Get profile xioDashboard */
export const getXioDashboard = async (data) => {
	const getXioDashboardCofig = {
	  baseURL: config.LOCAL_API_URL,
	  headers: {
		Authorization: `Basic ${token}`,
	  },
	  data,
	  method: "get",
	  url: "profile/xiodashboard",
	};
	try {
	  const getXioDashboard = await axios(getXioDashboardCofig);
	  if (getXioDashboard.status === 200) {
		return getXioDashboard;
	  }
	} catch (e) {
	  return e.response;
	}
  };