export const actionTypes = {
	MINT_REQUEST: "MINT_REQUEST",
	MINT_SUCCESS: "MINT_SUCCESS",
	MINT_FAILURE: "MINT_FAILURE",

	BURN_REQUEST: "BURN_REQUEST",
	BURN_SUCCESS: "BURN_SUCCESS",
	BURN_FAILURE: "BURN_FAILURE",

	SALE_REQUEST: "SALE_REQUEST",
	SALE_SUCCESS: "SALE_SUCCESS",
	SALE_FAILURE: "SALE_FAILURE",

	CANCEL_SALE_REQUEST: "CANCEL_SALE_REQUEST",
	CANCEL_SALE_SUCCESS: "CANCEL_SALE_SUCCESS",
	CANCEL_SALE_FAILURE: "CANCEL_SALE_FAILURE",

	BUY_REQUEST: "BUY_REQUEST",
	BUY_SUCCESS: "BUY_SUCCESS",
	BUY_FAILURE: "BUY_FAILURE",

	BID_REQUEST: "BID_REQUEST",
	BID_SUCCESS: "BID_SUCCESS",
	BID_FAILURE: "BID_FAILURE",

	CANCEL_BID_REQUEST: "CANCEL_BID_REQUEST",
	CANCEL_BID_SUCCESS: "CANCEL_BID_SUCCESS",
	CANCEL_BID_FAILURE: "CANCEL_BID_FAILURE",

	BID_AND_BURN_REQUEST: "BID_AND_BURN_REQUEST",
	BID_AND_BURN_SUCCESS: "BID_AND_BURN_SUCCESS",
	BID_AND_BURN_FAILURE: "BID_AND_BURN_FAILURE",

	NUMBER_OF_MINTED_REQUEST: "NUMBER_OF_MINTED_REQUEST",
	NUMBER_OF_MINTED_SUCCESS: "NUMBER_OF_MINTED_SUCCESS",
	NUMBER_OF_MINTED_FAILURE: "NUMBER_OF_MINTED_FAILURE",

	DELETENFT_REQUEST:"DELETENFT_REQUEST",
	DELETENFT_SUCCESS:"DELETENFT_SUCCESS",
	DELETENFT_FAILURE:"DELETENFT_FAILURE",

	TOTALTRADE_REQUEST:"TOTALTRADE_REQUEST",
	TOTALTRADE_SUCCESS:"TOTALTRADE_SUCCESS",
	TOTALTRADE_FAILURE:"TOTALTRADE_FAILURE",

	SEND_REQUEST: "SEND_REQUEST",
	SEND_SUCCESS: "SEND_SUCCESS",
	SEND_FAILURE: "SEND_FAILURE",

	RECEIVE_REQUEST: "RECEIVE_REQUEST",
	RECEIVE_SUCCESS: "RECEIVE_SUCCESS",
	RECEIVE_FAILURE: "RECEIVE_FAILURE",

	CANCEL_SEND_REQUEST: "CANCEL_SEND_REQUEST",
	CANCEL_SEND_SUCCESS: "CANCEL_SEND_SUCCESS",
	CANCEL_SEND_FAILURE: "CANCEL_SEND_FAILURE",

	PLACE_MOREOFFER_REQUEST:"PLACE_MOREOFFER_REQUEST",
	PLACE_MOREOFFER_SUCCESS:"PLACE_MOREOFFER_SUCCESS",
	PLACE_MOREOFFER_FAILURE:"PLACE_MOREOFFER_FAILURE",

	GET_ALLPLACEDOFFER_REQUEST:"GET_ALLPLACEDOFFER_REQUEST",
	GET_ALLPLACEDOFFER_SUCCESS:"GET_ALLPLACEDOFFER_SUCCESS",
	GET_ALLPLACEDOFFER_FAILURE:"GET_ALLPLACEDOFFER_FAILURE",

	ACCEPT_PLACEDOFFER_REQUEST:"ACCEPT_PLACEDOFFER_REQUEST",
	ACCEPT_PLACEDOFFER_SUCCESS:"ACCEPT_PLACEDOFFER_SUCCESS",
	ACCEPT_PLACEDOFFER_FAILURE:"ACCEPT_PLACEDOFFER_FAILURE",

	CANCEL_PLACEDOFFERS_REQUEST:"CANCEL_PLACEDOFFERS_REQUEST",
	CANCEL_PLACEDOFFERS_SUCCESS:"CANCEL_PLACEDOFFERS_SUCCESS",
	CANCEL_PLACEDOFFERS_FAILURE:"CANCEL_PLACEDOFFERS_FAILURE"
};