import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function Filetypecomman(props) {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const onDocumentLoadSuccess = ({ numPages }) => { setNumPages(numPages) }
	const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
	const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

	return (
		<>
			{(() => {
				if (props.fileType === "image" || props.fileType === "gif") {
					return (
						<div className="main-img detail_nft">
							<Card.Img
								variant="top"
								src={props.image}
								className="min-height-200-new11"
							/>
						</div>
					);
				} else if (props.fileType === "video") {
					return (
						<>
							<div className="main-img detail_nft">
								<div className="video-tag-common">
									<video className="video-player" preload="metadata" controls autoPlay muted playsInline controlsList="nodownload">
										<source src={props.image + "#t=0.2"} />
									</video>
								</div>
							</div>
						</>
					);
				} else if (props.fileType === "audio") {
					return (
						<>
							<div className="main-img detail_nft">
								<div className="audio-video-tag-common audio-height home-music detailAudio">
									<audio controls controlsList="nodownload">
										<source src={props.image} />
									</audio>
								</div>
							</div>
						</>
					);
				} else if (props.fileType === "application") {
					return (
						<div className="pdf-style detail_pdf">
							<Document className="pdf-style" file={props.image} onLoadSuccess={onDocumentLoadSuccess} >
								<Page pageNumber={pageNumber} className="pdf-style" />
							</Document>
							<p>Page: {pageNumber} of {numPages} </p>
							<i aria-hidden="true" className="fa fa-arrow-circle-left" style={{ fontSize: "24px", cursor: "pointer" }} onClick={goToPrevPage} />
							<i aria-hidden="true" className="fa fa-arrow-circle-right" style={{ fontSize: "24px", cursor: "pointer" }} onClick={goToNextPage} />
						</div>

					);
				}
			})()}
	</>);
}

export default Filetypecomman;
