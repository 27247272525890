const category = [
	"3D Art",
	"AI Art",
	"Animation",
	"Art",
	"Augmented Reality",
	"Avatar",
	"Collectibles",
	"Comic",
	"Crypto",
	"Digital Art",
	"Digital Asset",
	"Domain",
	"Fashion",
	"Fractional",
	"Gaming",
	"Gaming Asset",
	"Generative Art",
	"Governance Asset",
	"International",
	"Metaverse",
	"Mixed Reality",
	"Music",
	"Photography",
	"Physical Art",
	"Phygital Art",
	"Puzzle",
	"Tickets",
	"Token Gate NFT",
	"Trading Cards",
	"Utility",
	"Video",
	"Virtual Real Estate",
	"Virtual Reality"
]
export default category
