import {
	getSendStatus,
	getAcceptNFTStatus,
	getDeclinedNFTStatus,
	declinedNFTRequest
} from "../services/send";


/////////////////// Get send status action start////////////////
export let sendStatusAction = (data) => async (dispatch) => {
	const payload = await getSendStatus(data.data);
    return payload;

};
/////////////////// Get send status action end////////////////


///////////////////Receive nft status action start////////////////
export let checkAcceptAction = (data) => async () => {
	const payload = await getAcceptNFTStatus(data);
    return payload;
};

///////////////////Receive nft status action start////////////////


///////////////////Declined nft status action start////////////////
export let checkDeclinedNFTAction = () => async () => {
	const payload = await getDeclinedNFTStatus();
    return payload;
};
///////////////////Declined nft status action start////////////////

///////////////////Declined nft action start////////////////
export let declinedNFTRequestAction = (data) => async () => {
	const payload = await declinedNFTRequest(data);
    return payload;
};
///////////////////Declined nft action start///////////////

